import React from 'react';
import { Helmet } from 'react-helmet-async';

const FAQ = () => {
  return (
    <div className="max-w-3xl mx-10 p-8 w-[50%] justify-start">
      <Helmet>
        <title>Explore FAQs & Learn About the Merchant Navy with Karibik India Maritime - Merchant Navy Institute.</title>
        <meta name="description" content="Read FAQs and learn more about the merchant navy with Karibik India Maritime Academy, one of the top-rated merchant navy training institutes in India. Our academy has become specialized in providing maritime training and spreading marine education all over India. Explore our site to know more." />
        <meta name="keywords" content="institute for merchant navy in india,merchant navy course in india ,maritime academy in india ,merchant navy institute,india maritime academy
" />
      </Helmet>
      <h1 className="text-2xl font-semibold mb-2 ">Merchant Navy FAQs</h1>
      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">1. What is Merchant Navy?</h2>
        <p className="text-gray-700">Ships that carry goods, people or render services of a non-military nature constitute the merchant navy...</p>
      </div>
      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">2. Why Join Merchant Navy?</h2>
        <p className="text-gray-700">Merchant Navy is a very lucrative career, some of the many attractions of this profession are listed below:...</p>
      </div>
      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">3. Who should join Merchant Navy?</h2>
        <p className="text-gray-700">If you are adventurous person who likes to be outdoors and is not interested in a monotonous 9-5 job, then Merchant Navy is the best career option for you.</p>
      </div>
      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">4. How is Merchant Navy better than any other career option?</h2>
        <p className="text-gray-700">A student who passes his/her 10+2 exams and takes up any stream for further studies spends at least another 4 years in college...</p>
      </div>
      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">5. Sea of Opportunity?</h2>
        <p className="text-gray-700">There are various types of ships but the main job of a ship is to transport goods or people from one place to another...</p>
      </div>
    </div>
    
    
  );
};

export default FAQ;
